import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.sort.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "videoLabelConf",
    staticClass: "video-label-container"
  }, [_c("a-form-model", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "业务场景",
      prop: "sceneid"
    }
  }, [_c("a-select", {
    attrs: {
      disabled: _vm.isedit,
      placeholder: "请选择业务场景"
    },
    model: {
      value: _vm.form.sceneid,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sceneid", $$v);
      },
      expression: "form.sceneid"
    }
  }, _vm._l(_vm.labelSceneList, function (item) {
    return _c("a-select-option", {
      key: item.code,
      attrs: {
        value: item.code
      }
    }, [_vm._v(" " + _vm._s(item.desc) + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "关联角色",
      prop: "relationroleid"
    }
  }, [_c("a-select", {
    attrs: {
      disabled: _vm.isedit,
      placeholder: "请选择关联角色"
    },
    model: {
      value: _vm.form.relationroleid,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "relationroleid", $$v);
      },
      expression: "form.relationroleid"
    }
  }, _vm._l(_vm.roleList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s("".concat(item.name, "[").concat(item.status == 0 ? "正常" : "停用", "]")) + " ")]);
  }), 1)], 1), _vm._l(_vm.form.lables, function (ele, index) {
    return _c("div", {
      key: ele.grade,
      staticClass: "lables-wrapper"
    }, [index == 0 || _vm.form.lables[index - 1] && _vm.form.lables[index - 1].items.length ? _c("a-form-model-item", {
      attrs: {
        label: ele.gradename,
        prop: "lables"
      }
    }, [_c("div", {
      staticClass: "lables-grade"
    }, [_vm._l(ele.items, function (it, idx) {
      return _c("div", {
        key: "".concat(ele.grade).concat(it.id).concat(idx, "}"),
        on: {
          mouseleave: function mouseleave($event) {
            return _vm.hideMenu(ele.items[idx]);
          },
          contextmenu: function contextmenu($event) {
            return _vm.activeMenu($event, ele.items[idx]);
          }
        }
      }, [_c("a-dropdown", [_c("a-menu", {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: ele.items[idx].menuVisible,
          expression: "ele.items[idx].menuVisible"
        }],
        attrs: {
          slot: "overlay"
        },
        on: {
          mouseover: function mouseover($event) {
            return _vm.showMenu(ele.items[idx]);
          },
          mouseleave: function mouseleave($event) {
            return _vm.hideMenu(ele.items[idx]);
          }
        },
        slot: "overlay"
      }, [_c("a-menu-item", {
        key: "1",
        on: {
          click: function click($event) {
            return _vm.handleEdit(ele.items[idx]);
          }
        }
      }, [_vm._v(" 编辑 ")]), _c("a-menu-item", {
        key: "2",
        on: {
          click: function click($event) {
            return _vm.handleDel(ele.items, idx);
          }
        }
      }, [_vm._v(" 删除 ")])], 1), ele.items[idx].showInput ? _c("a-input", {
        staticStyle: {
          appearance: "button"
        },
        attrs: {
          type: "primary",
          value: ele.items[idx].name,
          maxLength: 100,
          readOnly: !ele.items[idx].showInput,
          placeholder: "请输入标签名"
        },
        on: {
          input: function input($event) {
            return _vm.change($event, ele.grade, idx);
          },
          pressEnter: function pressEnter($event) {
            return _vm.pressEnter($event, ele.grade, idx);
          },
          blur: function blur($event) {
            return _vm.pressEnter($event, ele.grade, idx);
          }
        }
      }) : _c("a-button", {
        staticClass: "lable-item",
        attrs: {
          ghost: _vm.checkParentsIds.includes(ele.items[idx].id),
          type: ele.items[idx].id == _vm.checkLableId || _vm.checkParentsIds.includes(ele.items[idx].id) ? "primary" : "default"
        },
        on: {
          click: function click($event) {
            return _vm.setCheckItem(ele.items[idx], ele.grade);
          }
        }
      }, [_vm._v(_vm._s(ele.items[idx].name))])], 1)], 1);
    }), _c("a-button", {
      attrs: {
        icon: "plus"
      },
      on: {
        click: function click($event) {
          return _vm.handleAdd(ele.grade);
        }
      }
    }, [_vm._v(" 添加 ")])], 2)]) : _vm._e()], 1);
  }), _c("a-form-model-item", {
    attrs: {
      label: " "
    }
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(" 确定 ")])], 1)], 2), _c("a-modal", {
    attrs: {
      width: "800px",
      title: "编辑",
      footer: null,
      "get-container": function getContainer() {
        return _vm.$refs.videoLabelConf;
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form-model", {
    ref: "ruleForm2",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules2,
      labelCol: {
        span: 4
      }
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "标签名称",
      prop: "name"
    }
  }, [_c("a-input", {
    attrs: {
      maxLength: 100,
      placeholder: "请输入标签名称"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "排序"
    }
  }, [_c("a-input-number", {
    staticClass: "input-num",
    attrs: {
      placeholder: "填写数值，越大排序越靠前"
    },
    model: {
      value: _vm.formData.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "sort", $$v);
      },
      expression: "formData.sort"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "所属分组"
    }
  }, [_c("a-input-number", {
    staticClass: "input-num",
    attrs: {
      placeholder: "填写数值，数值不同即互斥"
    },
    model: {
      value: _vm.formData.groupid,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "groupid", $$v);
      },
      expression: "formData.groupid"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "备注",
      prop: "remarks"
    }
  }, [_c("a-input", {
    attrs: {
      maxLength: 20,
      placeholder: "填写备注"
    },
    model: {
      value: _vm.formData.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "remarks", $$v);
      },
      expression: "formData.remarks"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: " "
    }
  }, [_c("a-button", {
    on: {
      click: _vm.handleReset
    }
  }, [_vm._v("取消")]), _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("确定")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };